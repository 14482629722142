<script lang="ts" setup>
import { useScrollLock } from '@vueuse/core'

const { pageRef } = useCustomerModulePage()
const isMenuOpen = useScrollLock(pageRef)

const config = useRuntimeConfig()

useHead({
  title: useNuxtApp().$i18n.t('appNameForTitle'),
  script: computed(() => {
    if (!config.public.ccm.src) {
      return []
    }
    return [
      { src: config.public.ccm.src, referrerpolicy: 'origin' },
    ]
  }),
})
</script>

<template>
  <div ref="pageRef" class="h-screen overflow-y-auto">
    <div class="CustomerModule relative font-myriad">
      <CustomerModuleLayoutNavbar class="PagePadding" @click-menu="isMenuOpen = !isMenuOpen" />
      <CustomerModuleLayoutMegaMenu v-model="isMenuOpen" />
      <div class="pt-[67px]">
        <slot />
      </div>
    </div>
  </div>
</template>

<style>
.CustomerModule .PagePadding {
    @apply px-5 md:px-8 lg:px-12 xl:px-20
}
</style>
